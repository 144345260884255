//const getUri() = 'http://localhost:5002/api/';
//const getUri() = 'https://digitalsignatureapi.royalfinances.ca/api/'; // Royal
//const getUri() = 'https://digitalsignatureapi.creditbook.ca/api/'; // Royal DEMO
//const getUri() = 'https://digitalsignatureapi.soniccash500.com/api/'; // Sonic
//const getUri() = 'https://digitalsignatureapi.pretc.ca/api/'; // PretC
//const getUri() = 'https://digitalsignatureapi.alphaloans.ca/api/'; // Alpha
//const getUri() = 'https://digitalsignatureapi.expresscreditplus.com/api/'; // Express Credit
//const getUri() = 'https://digitalsignatureapi.solutions500.com/api/'; // Solutions500
//const getUri() = 'http://digitalsignatureapi.pretcash2000.com/api/'; // Pret Cash 2000
//const getUri() = 'https://digitalsignatureapi.24hrcash.ca/api/'; // 24hr Cash
//const getUri() = 'https://digitalsignatureapi.financedirect.ca/api/'; // Finance Directe

/*
const requestHeadersContent = () => ({
    "Content-Type": "application/json",
});
*/

function getUri(){
    var _origin = window.location.hostname;
    if (_origin === 'localhost') {
        return `${window.location.protocol}//${_origin.concat(':5002')}`;
    } else {
        var _originSplit = _origin.split('.');
        return `${window.location.protocol}//${_originSplit[0].concat('api')}.${_originSplit[1]}.${_originSplit[2]}`;
    }
}

export const getContract = async (guid) => {
    const requestUrl = new URL(`api/contract/${guid}`, getUri());
    const response = await fetch(requestUrl);
    return await response.json();
}

export const signContract = async (guid, clientIp, sigBase64, browser) => {
    //const requestUrl = new URL(`contract/${guid}?dateOfBirth=${dateOfBirth}`, getUri());
    const requestUrl = new URL(`api/contract/${guid}/${clientIp}`, getUri());
    //const requestUrl = new URL("contract", getUri());
    var _body = {
        sigBase64: sigBase64,
        browser: browser
    };

    try {
        const response = await fetch(requestUrl, {
            method: 'PUT',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(_body)
        });
        return response.json();
    } catch (err) {
        return { errorCode: err };
    }
}