import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
// import PdfViewer from './PdfViewer';
import Alert from '@material-ui/lab/Alert';
import InLine from "./PDFViewerInline";

const useStyles = makeStyles({
    alert: {
        marginTop: 15,
        marginBottom: 15,
    }
});

const Signed = (props) => {
    const classes = useStyles();
    const { contract } = props;
    const { t } = useTranslation();
    return (
        <div>
            <div style={{ border: "1px solid #dadada" }} className="pdfWrapper">
                {/* <PdfViewer path={contract.contractUrl} /> */}
                <InLine url={contract.contractUrl} clientId={contract.adobeClientId}></InLine>
            </div>
            {!contract.isPrevious &&
                <div>
                    <Alert className={classes.alert} severity="success">{t('home.dateSigned') + ": " + contract.timeStampFormatted}</Alert>
                    <img alt="Signature" src={contract.signatureUrl} />
                </div>
            }
        </div>
    );
};

export default Signed;
