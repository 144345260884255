import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas'
import { Button, Card, CardActions, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles({
    root: {
        borderRadius: 10,
        borderStyle: "dashed",
        borderColor: red,
        "& .MuiCardHeader-root": {
            backgroundColor: "#f4f4f4",
        },
        "& .MuiCardHeader-action": {
            margin: 0,
        },
        "& .MuiCardContent-root": {
        }
    },
});

const SignedCanvas = (props) => {
    const classes = useStyles();
    const sigCanvas = useRef({});
    const { t } = useTranslation();
    const termsAccepted = props.termsAccepted;
    const sigIsEmpty = props.sigIsEmpty;
    const [loading, setLoading] = useState(false);

    const onSubmit = () => {
        setLoading(true);
        props.onSubmit(true);
    }

    const onEnd = () => {
        props.isEmpty(sigCanvas.current.isEmpty());
        props.sigBase64(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    }

    const clear = () => {
        sigCanvas.current.clear();
        props.isEmpty(true);
    }

    return (
        <Card className={classes.root} variant="outlined" elevation={0}>
            <CardHeader
                title={t('home.draw')}
            />
            <CardContent>

                <SignatureCanvas
                    ref={sigCanvas}
                    penColor='black'
                    onEnd={onEnd}
                />
            </CardContent>
            <CardActions>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    //disabled={!termsAccepted || !dateOfBirth}
                    disabled={!termsAccepted || sigIsEmpty || loading}
                    onClick={onSubmit}
                >
                    {loading &&
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" }}
                        />
                    }
                    {t('home.submit')}
                </Button>
                <Button
                    variant="outlined"
                    className={classes.submit}
                    onClick={clear}
                >
                    {t('home.clear')}
                </Button>
            </CardActions>
        </Card>
    );
};

export default SignedCanvas;