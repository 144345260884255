import React from 'react';
import { CircularProgress } from '@material-ui/core';

const LoadingPage = () => {
    return <div style={{height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <CircularProgress 
            size={100} 
            variant="indeterminate"
        />
    </div>
}

export default LoadingPage;