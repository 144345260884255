import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import './custom.css';
// import './custom1.css';
//import './pdf.css'

import en from './localization/en.json';
import fr from './localization/fr.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en,
      fr
    },
    lng: "en",
    fallbackLng: "en"
  });

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Layout>
        <Route exact path='/:guid' component={Home} />
      </Layout>
    );
  }
}
