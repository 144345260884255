import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PdfViewer from './PdfViewer';
import LoadingPage from './LoadingPage';
//import SuccessPage from './SuccessPage';
import Signed from './Signed';
import SignedCanvas from './SigCanvas';
import NotFoundPage from './NotFoundPage';
import { getContract, signContract } from '../api';
import { Checkbox, FormControlLabel } from '@material-ui/core';
//import Alert from '@material-ui/lab/Alert';
import publicIp from "public-ip";
import InLine from "./PDFViewerInline";
import { userBrowser } from "../UserInfo";

const useStyles = makeStyles({
  root: {
    "& .MuiTypography-h5": {
      fontSize: 16,
      fontWeight: 600
    }
  },
  checkbox: {
    padding: "15px 0",
  },
  alert: {
    marginTop: 15,
  }
});

export const Home = (props) => {
  const classes = useStyles();
  const { guid } = useParams();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [contract, setContract] = useState();
  const [termsAccepted, setTermsAccepted] = useState(false);
  //const [dateOfBirth, setDateOfBirth] = useState();
  const [sigIsEmpty, setSigIsEmpty] = useState(true);
  const [sigBase64, setSigBase64] = useState();

  var clientIp = null;
  (async () => {
    clientIp = await publicIp.v4({
      fallbackUrls: [
        'https://ifconfig.co/ip'
      ]
    });
  })();

  var browser = userBrowser();

  useEffect(() => {
    getContract(guid)
      .then(result => {
        if (result.status !== 404) {

          // if (result.contractUrl.includes("http://")) {
          //   var splitUrl = result.contractUrl.replace("http://", "").split("/");
          //   result.contractUrl = window.location.origin + "/ClientContracts/" + splitUrl[2];
          // }
          // if (result.contractUrl.includes("https://")) {
          //   var splitUrl = result.contractUrl.replace("https://", "").split("/");
          //   result.contractUrl = window.location.origin + "/ClientContracts/" + splitUrl[2];
          // }

          setContract(result);
          // Translate language from non-standard to standard
          switch (result.languageCode) {
            case 'F':
              i18next.changeLanguage('fr');
              break;
            case 'E':
            default:
              i18next.changeLanguage('en');
          }

        }
        setTimeout(() => {
          setLoading(false);
        }, 2000)

      });
  }, [guid]);

  const onSubmit = () => {
    signContract(guid, clientIp, sigBase64, browser)
      .then(result => setContract({
        ...contract,
        ...result
      }));
  }

  const handleSigIsEmpty = (value) => {
    //console.log("Message" + value);
    setSigIsEmpty(value);
  }

  const handleSigBase64 = (value) => {
    //console.log("Message" + value);
    setSigBase64(value);
  }

  if (loading) return <LoadingPage />
  if (!loading && !contract) return <NotFoundPage />
  if (contract.isSigned) return <Signed contract={contract} />

  return (
    // <div style={{ border: "1px solid #dadada" }}>
    <div className={classes.root}>
      <div style={{ border: "1px solid #dadada" }} className="pdfWrapper">
        {/* <PdfViewer path={contract.contractUrl} /> */}
        <InLine url={contract.contractUrl} clientId={contract.adobeClientId}></InLine>
      </div >
      {/* <div>
        <InLine url={contract.contractUrl} clientId={contract.adobeClientId}></InLine>
      </div> */}
      <FormControlLabel
        control={
          <Checkbox
            name="checkedC"
            onChange={e => setTermsAccepted(e.target.checked)}
          />
        }
        label={t('home.disclaimer')}
        className={classes.checkbox}
      />
      <SignedCanvas isEmpty={handleSigIsEmpty} sigBase64={handleSigBase64} onSubmit={onSubmit} termsAccepted={termsAccepted} sigIsEmpty={sigIsEmpty} />
      {/* <Alert className={classes.alert} severity="error">{t('home.error')} : {t(`error.${contract.errorCode}`)}</Alert> */}
    </div>
  );
};
